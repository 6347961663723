<template>
<div class="bg-white">
  <b-container fluid class="bg-white pb-3">
    <b-row>
      <b-col>
        <h1>Historical prices</h1>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="bg-white pb-5" v-if="!steps.loadedData" style="min-height: 100vh">
    <b-row class="mb-5" v-if="steps.schema">
      <b-col>
        <div>
          <h3>Step 1 - choose schema</h3>
          <input v-if="schema.length === 0" type="file" id="inputSchema" @change="schemaFileRegister" />
          <b-button v-if="schemaFile && schema.length === 0" @click="schemaFileProcess">Load schema</b-button>
        </div>
        <div v-if="schema.length > 0">
          <h5>Loaded schema</h5>
          <div v-for="(line, index) in schema" :key="'schema-line-' + index">
            {{line}}
          </div>
          <div>
            <b-button @click="schemaCancel">Reload schema</b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="steps.data">
      <b-col>
        <h3>Step 2 - load data</h3>
        <input v-if="!steps.loadedData" type="file" id="input"  @change="dataFileRegister" />
        <b-button v-if="file && !steps.loadedData" @click="dataFileStream">Stream</b-button>
        <div class="my-4" v-if="steps.loadingData">
          <b-spinner label="Spinning"></b-spinner>
        </div>
        <div v-if="steps.loadingData || steps.loadedData">
          processed rows of data: {{lines}}
        </div>
        <div v-if="steps.loadedData">
          <b-button v-if="file" @click="dataFileReload">Reload</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="steps.loadingData" class="my-5 py-5">
      <b-col>
        <h3>Loading data</h3>
        <div><b-spinner label="Spinning"></b-spinner></div>
        <h4>processed rows of data: {{lines}}</h4>
      </b-col>
    </b-row>
  </b-container>
  <b-container fluid v-if="steps.loadedData" style="min-height: 100vh">
    <b-row>
      <b-col>
        <div>
          <b-tabs content-class="mt-3">
            <b-tab title="Preview" active>
              <div>Records loaded: {{lines}}. A quick preview of the first 30 records</div>
              <div id="preview"></div>
              <b-button variant="danger" @click="dataFileReload">Reload - start again</b-button>
            </b-tab>
            <b-tab title="Test">
              <b-container fluid>
                <b-row>
                  <b-col>
                    <div id="testsPie"></div>
                  </b-col>
                  <b-col>
                    <div id="recordsPie"></div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div v-for="(test, index) in tests" :key="'test-wrapper-' + index">
                      <test-result :test="test" />
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Explore">
              <b-container fluid>
                <b-row>
                  <b-col>
                    <h3>Security dashbaord</h3>
                    <div>There are {{symbols.length}} securities loaded</div>
                    <div>
                      <b-form inline>
                        <b-form-input class="mr-2" v-model="symbolSearchInput" id="searchSymbol" placeholder="search symbol" />
                        <b-button v-if="symbolsSuggest.length > 0" @click="symbolShow">Go</b-button>
                      </b-form>
                    </div>
                    <div>
                      <div v-for="symbol in symbolsSuggest" :key="'sumbol-suggest-' + symbol">
                        {{symbol}}
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div>
                      <div id="priceChart"></div>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <div id="historgramChart"></div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div>
                      <div id="scatterChart"></div>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <div id="violinChart"></div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import * as dfd from 'danfojs/src/index.js'
import _ from 'lodash'
import async from 'async'
import { assert } from 'chai'
import parse from 'csv-parse'
import Plotly from 'plotly.js-dist'
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
import TestResult from '@/views/apps/analytics/TestResult.vue'

hljs.registerLanguage('javascript', javascript)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

let output = []
let failedRecords = []

export default {
  components: {
    TestResult
  },
  computed: {
    symbolsSuggest: function () {
      if (this.symbolSearchInput.length > 0) {
        return this.symbols.filter(symbol => symbol.toUpperCase().includes(this.symbolSearchInput.toUpperCase())).slice(0, 20)
      }
      return []
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  beforeDestroy: function () {
    this.dataFileReload()
  },
  created: function () {
    this.$stat.log({ page: 'Analytics app start', action: 'open Analytics app' })
    window.Plotly = Plotly

    this.parser = parse({
      delimiter: ','
    })

    this.parser.on('readable', async () => {
      const errors = []
      const recordRaw = this.parser.read()
      const record = recordRaw
      record[this.col.id] = Number(record[this.col.id])
      record[this.col.price] = Number(record[this.col.price])
      // record[this.col.momentum] = Number(record[this.col.momentum])
      if (this.headCounter < 30) {
        this.headData.push(record)
      }
      // trying to use native JS instead of Lodash for performance in large data sets
      this.tests.forEach(test => {
        try {
          test.logic(record, recordRaw)
        } catch (e) {
          test.results.push(recordRaw)
          errors.push(e.message)
        }
      })
      if (errors.length > 0) {
        failedRecords.push({
          record: recordRaw,
          errors: errors
        })
      }
      this.headCounter += 1
      output.push(record)
    })

    const tests = [{
      name: 'Date exists',
      results: [],
      logic: (record, recordRaw) => {
        assert.exists(record[this.col.symbol], 'Date exists')
      }
    }, {
      name: 'Symbol exists',
      results: [],
      logic: (record, recordRaw) => {
        assert.exists(record[this.col.symbol], 'Symbol exists')
      }
    }, {
      name: 'Price exists',
      results: [],
      logic: (record, recordRaw) => {
        assert.exists(record[this.col.price], 'Price exists')
      }
    }, {
      name: 'Price is numberic',
      results: [],
      logic: (record, recordRaw) => {
        assert.isFinite(record[this.col.price], 'Price has to be a finite number')
      }
    }, {
      name: 'Price is not high',
      results: [],
      logic: (record, recordRaw) => {
        assert.isBelow(record[this.col.price], 100000, 'Price has to be below 100,000')
      }
    }, {
      name: 'Price is above zero',
      results: [],
      logic: (record, recordRaw) => {
        assert.isAbove(record[this.col.price], 0, 'Price has to be above zero')
      }
    }
    ]
    this.tests = tests
  },
  data () {
    return {
      completed: false,
      col: {},
      file: null,
      headCounter: 0,
      headData: [],
      lines: 0,
      parser: null,
      reader: null,
      schema: [],
      schemaFile: null,
      schemaFileContent: null,
      show: false,
      steps: {
        schema: true,
        data: false,
        loadingData: false,
        loadedData: false
      },
      symbols: [],
      symbolSearchInput: '',
      test: {},
      tests: []
    }
  },
  methods: {
    analyticsPrepare: async function () {
      this.symbols = _
        .chain(output)
        .map(row => row[this.col.symbol])
        .uniq()
        .value()
      await delay(50)
      const df = new dfd.DataFrame(this.headData, { columns: this.schema })
      const config = {
        layout: {
          height: 800,
          margin: {
            l: 10,
            r: 10,
            b: 10,
            t: 10,
            pad: 2
          }
        }
      }
      df.plot('preview').table(config)
      let testsPassed = 0
      let testsFailed = 0
      _.each(this.tests, test => {
        if (test.results.length === 0) {
          testsPassed += 1
        } else {
          testsFailed += 1
        }
      })
      const traceTests = { values: [testsPassed, testsFailed], labels: ['passed', 'failed'], name: 'Tests', hole: 0.4, type: 'pie' }
      const layoutTests = { title: 'Tests summary', annotations: [{ font: { size: 15 }, showarrow: false, text: 'tests' }] }
      Plotly.newPlot('testsPie', [traceTests], layoutTests)
      const traceRecord = { values: [output.length - failedRecords.length, failedRecords.length], labels: ['passed', 'failed'], name: 'Records', hole: 0.4, type: 'pie' }
      const layouRecord = { title: 'Records summary', annotations: [{ font: { size: 15 }, showarrow: false, text: 'records' }] }
      Plotly.newPlot('recordsPie', [traceRecord], layouRecord)
    },
    schemaFileRegister: async function () {
      this.schemaFile = document.getElementById('inputSchema').files[0]
    },
    schemaFileProcess: async function () {
      this.schemaFileContent = await this.schemaFile.text()
      this.schema = _.split(this.schemaFileContent, ',')
      _.each(this.schema, (value, index) => {
        this.col[value] = index
      })
      _.each(this.tests, (value, index) => {
        this.test[value.id] = index
      })
      this.steps.data = true
    },
    schemaCancel: function () {
      this.schema = []
      this.schemaFileContent = null
      this.steps.data = false
    },
    symbolShow: function () {
      const records = []
      _.each(output, row => {
        if (row[this.col.symbol].toUpperCase() === this.symbolSearchInput.toUpperCase()) {
          const item = {
            date: row[this.col.date],
            price: row[this.col.price]
          }
          records.push(item)
        }
      })
      const recordsSorted = _.sortBy(records, ['date'])
      const date = []
      const price = []
      _.each(recordsSorted, row => {
        date.push(row.date)
        price.push(row.price)
      })
      const returns = _.map(price, (p, i) => {
        if (i === 0) {
          return null
        } else {
          return (p / price[i - 1]) - 1
        }
      })
      const trace = { x: date, y: price, type: 'scatter' }
      const layoutPrice = { title: 'Price history' }
      Plotly.newPlot('priceChart', [trace], layoutPrice)
      const traceHist = { x: returns, type: 'histogram' }
      const layoutHist = { title: 'Daily returns frequency' }
      Plotly.newPlot('historgramChart', [traceHist], layoutHist)
      const traceScatter = { x: _.initial(returns), y: _.tail(returns), mode: 'markers', type: 'scatter' }
      const layoutScatter = { title: 'Daily returns autocorrelation' }
      Plotly.newPlot('scatterChart', [traceScatter], layoutScatter)
      const traceViolin = { y: returns, type: 'violin', points: 'none', box: { visible: true }, boxpoints: false, line: { color: 'black' }, fillcolor: '#8dd3c7', opacity: 0.6, meanline: { visible: true }, x0: 'returns' }
      const layoutViolin = { title: 'Daily returns distribution', yaxis: { zeroline: false } }
      Plotly.newPlot('violinChart', [traceViolin], layoutViolin)
    },
    dataFileRegister: function () {
      this.file = document.getElementById('input').files[0]
    },
    dataFileReload: function () {
      this.completed = false
      this.lines = null
      output = []
      failedRecords = []
      this.steps.data = false
      this.steps.loadingData = false
      this.steps.loadedData = false
      this.file = null
      this.stream = null
      this.reader = null
      this.schema = []
      this.schemaFileContent = null
      this.steps.schema = true
    },
    dataFileStream: async function () {
      this.steps.schema = false
      this.steps.data = false
      this.steps.loadingData = true
      this.stream = await this.file.stream()
      this.reader = await this.stream.getReader()
      this.show = true
      await async.until(async.asyncify(this.testStream), async.asyncify(this.processChunk))
      this.steps.loadingData = false
      this.steps.loadedData = true
      this.lines = output.length
      this.analyticsPrepare()
    },
    processChunk: async function () {
      await this.log()
      const chunk = await this.reader.read()
      if (chunk.done) {
        console.log('Stream complete')
        this.completed = true
        return
      }
      this.parser.write(chunk.value)
      this.lines = output.length
    },
    testStream: function () {
      return this.completed
    },
    log: async function () {
      await delay(1)
      return true
    }
  }
}
</script>
<style lang="scss">
/*
Original highlight.js style (c) Ivan Sagalaev <maniac@softwaremaniacs.org>
*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #F0F0F0;
}

/* Base color: saturation 0; */

.hljs,
.hljs-subst {
  color: #444;
}

.hljs-comment {
  color: #888888;
}

.hljs-keyword,
.hljs-attribute,
.hljs-selector-tag,
.hljs-meta-keyword,
.hljs-doctag,
.hljs-name {
  font-weight: bold;
}

/* User color: hue: 0 */

.hljs-type,
.hljs-string,
.hljs-number,
.hljs-selector-id,
.hljs-selector-class,
.hljs-quote,
.hljs-template-tag,
.hljs-deletion {
  color: #880000;
}

.hljs-title,
.hljs-section {
  color: #880000;
  font-weight: bold;
}

.hljs-regexp,
.hljs-symbol,
.hljs-variable,
.hljs-template-variable,
.hljs-link,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #BC6060;
}

/* Language color: hue: 90; */

.hljs-literal {
  color: #78A960;
}

.hljs-built_in,
.hljs-bullet,
.hljs-code,
.hljs-addition {
  color: #397300;
}

/* Meta color: hue: 200 */

.hljs-meta {
  color: #1f7199;
}

.hljs-meta-string {
  color: #4d99bf;
}

/* Misc effects */

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
/*
github.com style (c) Vasily Polovnyov <vast@whiteants.net>
*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #333;
  background: #f8f8f8;
}

.hljs-comment,
.hljs-quote {
  color: #998;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #333;
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #008080;
}

.hljs-string,
.hljs-doctag {
  color: #d14;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #900;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-type,
.hljs-class .hljs-title {
  color: #458;
  font-weight: bold;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #000080;
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #009926;
}

.hljs-symbol,
.hljs-bullet {
  color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #0086b3;
}

.hljs-meta {
  color: #999;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
</style>

<template>
<div class="bg-white">
  <div class="py-2">
    <b-button v-if="test.results.length > 0" variant="danger" class="mx-2" @click="collapseVisible = !collapseVisible">{{test.results.length}} items failed</b-button>
    <b-button v-if="test.results.length === 0" variant="success" class="mx-2" @click="collapseVisible = !collapseVisible">passed</b-button>
    {{test.name}}
  </div>
  <div>
    <b-collapse id="collapse-1" v-model="collapseVisible" class="my-5">
      <h3>Test details</h3>
      <div><strong>Test logic</strong></div>
      <pre>
        <code class="language-javascript" v-html="test.logic">
        </code>
      </pre>
      <div v-if="results.length > 0">
        <div><strong>Failed data (up to 20 records)</strong></div>
        <div v-for="(row, index) in results" :key="'test-high-price-' + index">
          {{index + 1}} - {{row}}
        </div>
      </div>
    </b-collapse>
  </div>
</div>
</template>

<script>
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'

hljs.registerLanguage('javascript', javascript)

export default {
  props: ['test'],
  components: {
  },
  computed: {
    results: function () {
      return this.test.results.slice(0, 20)
    }
  },
  created: function () {
  },
  mounted: function () {
    hljs.highlightAll()
  },
  data () {
    return {
      collapseVisible: false
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
/*
Original highlight.js style (c) Ivan Sagalaev <maniac@softwaremaniacs.org>
*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #F0F0F0;
}

/* Base color: saturation 0; */

.hljs,
.hljs-subst {
  color: #444;
}

.hljs-comment {
  color: #888888;
}

.hljs-keyword,
.hljs-attribute,
.hljs-selector-tag,
.hljs-meta-keyword,
.hljs-doctag,
.hljs-name {
  font-weight: bold;
}

/* User color: hue: 0 */

.hljs-type,
.hljs-string,
.hljs-number,
.hljs-selector-id,
.hljs-selector-class,
.hljs-quote,
.hljs-template-tag,
.hljs-deletion {
  color: #880000;
}

.hljs-title,
.hljs-section {
  color: #880000;
  font-weight: bold;
}

.hljs-regexp,
.hljs-symbol,
.hljs-variable,
.hljs-template-variable,
.hljs-link,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #BC6060;
}

/* Language color: hue: 90; */

.hljs-literal {
  color: #78A960;
}

.hljs-built_in,
.hljs-bullet,
.hljs-code,
.hljs-addition {
  color: #397300;
}

/* Meta color: hue: 200 */

.hljs-meta {
  color: #1f7199;
}

.hljs-meta-string {
  color: #4d99bf;
}

/* Misc effects */

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
/*
github.com style (c) Vasily Polovnyov <vast@whiteants.net>
*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #333;
  background: #f8f8f8;
}

.hljs-comment,
.hljs-quote {
  color: #998;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #333;
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #008080;
}

.hljs-string,
.hljs-doctag {
  color: #d14;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #900;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-type,
.hljs-class .hljs-title {
  color: #458;
  font-weight: bold;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #000080;
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #009926;
}

.hljs-symbol,
.hljs-bullet {
  color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #0086b3;
}

.hljs-meta {
  color: #999;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
</style>
